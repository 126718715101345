<template>
  <div class="popup">
    <div
      class="hang-btn"
      @click.stop="isShow2 = true"
      :class="isShow ? 'active' : ''"
    >
      <img src="../assets/course.png" alt="" />
      <div class="left-box">
        <div class="left" @click.stop="isShow = !isShow">
          <img src="../assets/courseRight.png" alt="" />
        </div>
      </div>
    </div>
    <div class="popup-box" :class="isShow2 ? 'active-two' : ''">
      <div class="top">
        <div class="title">相关课程</div>
        <div v-if="allApplyBtn" class="orderBtn" @click="clickFn()">
          <i><img src="../assets/order.png" alt="" /></i
          ><span>一键预约所有课程</span>
        </div>
        <div @click="isShow2 = false"><i class="iconfont icon-close"></i></div>
      </div>
      <div class="main">
        <div class="video-list">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allApplyBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      isShow2: false,
      timer: null,
    };
  },
  methods: {
    clickFn() {
      this.$emit("allApplyFn");
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  font-size: 0.12rem;
  color: #fff;
  .hang-btn {
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 20%;
    width: 0.73rem;
    border-radius: 50%;
    height: 0.73rem;
    transition: all 1s;
    right: 0;
    position: fixed;
    text-align: center;
    // transform: translateX(70%);
    transform: translateX(20%);
    img {
      width: 100%;
      height: 100%;
    }
    .left {
    }
    i {
      font-size: 1.8rem;
    }
    .left-box {
      position: absolute;
      transform: translate(-20%, -50%);
      top: 50%;
    }
    .left {
      // display: none;
      transition: all 1s;
      width: 0.17rem;
      height: 0.17rem;
      position: relative;
      // line-height: 1.5rem;
      // background-color: rgba($color: #fff, $alpha: 0.7);
      border-radius: 50%;
      overflow: hidden;
      transform-origin: center;
      img {
        width: 0.17rem;
        height: 0.17rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      // i {
      //   font-size: 1.3rem;
      //   color: #49b67b;
      //   font-weight: 900;
      // }
    }
  }
  .active {
    transform: translateX(70%);
    .left {
      transform: rotate(180deg);
    }
  }
  .popup-box {
    z-index: 999;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    transition: all 0.5s;
    transform: translateY(100%);
    .top {
      // height: 3.56rem;
      width: 100%;
      border-top-left-radius: 0.15rem;
      border-top-right-radius: 0.15rem;
      overflow: hidden;
      background-color: #49b67b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.22rem 0.19rem;
      box-sizing: border-box;
      i {
        font-size: 0.25rem;
        font-weight: 900;
      }
      .title {
        font-size: 0.18rem;
        font-weight: 900;
      }
      .orderBtn {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 0.25rem;
        padding: 0 0.15rem;
        border: 1px solid #fff;
        border-radius: 0.125rem;
        i {
          margin-right: 0.03rem;
          display: inline-block;
          width: 0.15rem;
          height: 0.15rem;
          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
    .main {
      height: 3.56rem;
      width: 100%;
      box-sizing: border-box;
      background-color: #f3f3f4;
      padding: 0 0.12rem;
      padding-top: 0.2rem;
      overflow-y: scroll;
    }
  }
  .active-two {
    transform: translateY(0);
  }
}
@font-face {
  font-family: "iconfont"; /* Project id 2931857 */
  src: url("//at.alicdn.com/t/font_2931857_h9wap1vgvem.woff2?t=1636705581255")
      format("woff2"),
    url("//at.alicdn.com/t/font_2931857_h9wap1vgvem.woff?t=1636705581255")
      format("woff"),
    url("//at.alicdn.com/t/font_2931857_h9wap1vgvem.ttf?t=1636705581255")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e61a";
}
</style>
