import request from "@/utils/request";

// 直播列表
export function liveList(data) {
  return request({
    url: "/qd/Live/liveListType",
    method: "post",
    data,
  });
}

// 直播详情
export function liveInfo(data) {
  return request({
    url: "/qd/Live/liveParticulars",
    method: "post",
    data,
  });
}

// 直播标题状态
export function livetitle(data) {
  return request({
    url: "/qd/Live/getState",
    method: "post",
    data,
  });
}

// 直播简介
export function liveBrief(params) {
  return request({
    url: "/qd/Live/whApi",
    method: "get",
    params,
  });
}

// 直播加pv
export function livePv(data) {
  return request({
    url: "/qd/Live/UpPv",
    method: "post",
    data,
  });
}

// 查询直播or点播
export function demand(params) {
  return request({
    url: "/getWebinarState",
    method: "get",
    params,
  });
}

// 修改观看渠道
export function watchSource(data) {
  return request({
    url: "/saveWatchSource",
    method: "post",
    data,
  });
}
