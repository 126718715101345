<template>
  <div class="watch-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="top-bar">
        <div class="left">
          <h1 class="subject">{{ liveData.subject }}</h1>
          <p class="time" v-if="liveData.type == 0 && liveData.liveStatus == 0">
            {{ "开始时间： " + liveData.liveTime }}
          </p>
        </div>
        <!-- <button @click="collectFn()">
          {{ collectStatus ? "已收藏" : "收藏" }}
        </button> -->
        <div class="btn-box">
          <div v-if="liveData.invitation_switch" class="invite" @click="makeInvite()"></div>
        <div :class="['icon-btn', collectStatus ? 'yessel' : 'nosel']" @click="collectFn()"></div>
        </div>
      </div>
      <div class="video-wrap">
        <iframe
          ref="iframe"
          border="0"
          frameborder="0"
          allowfullscreen="true"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div class="bottom-tab">
        <div class="left">
          <span @click="spanActive = true" :class="spanActive ? 'active' : ''"
            >简介</span
          >
          <span
            v-if="liveData.T && liveData.T.length > 0"
            @click="spanActive = false"
            :class="!spanActive ? 'active' : ''"
            >相关课程</span
          >
        </div>
        <div
          @click="allApplyFn()"
          class="right"
          v-if="!spanActive && allApplyBtn"
        >
          <i>
            <img src="../../assets/order.png" alt="" />
          </i>
          <span>一键预约所有课程</span>
        </div>
      </div>
      <div class="live-box">
        <div v-if="spanActive" class="info" v-html="liveBriefData"></div>
        <div v-else class="course">
          <pc-video
            v-for="(item, i) in liveData.T"
            :key="i"
            :data="item"
            @refDataList="getLiveInfo()"
          ></pc-video>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
    <button
      @click="appointmentFn()"
      :class="['apply-btn', liveData.ymnSubscribe != null ? 'active' : '']"
      v-if="liveData.type == 0 && liveData.liveStatus == 0"
    >
      {{ liveData.ymnSubscribe == null ? "立即预约" : "已预约" }}
    </button>
    <!-- 邀请卡弹框 -->
    <div v-if="inviteVisible" class="invite-box">
      <vue-canvas-poster
      v-if="!inviteImg"
        :widthPixels="1000"
        :painting="painting"
        @success="success"
        @fail="fail"
      ></vue-canvas-poster>
      <div class="img-box">
        <img :src="inviteImg" alt="">
        <div class="tips">
          <p>邀请卡已生成，赶快去分享</p>
          <div style="cursor: pointer" @click="closeInvite()">
            <img src="../../assets/close3.png" alt="">
          </div>
        </div>
        <div class="down">
          <a :href="inviteImg" :download="info.subject + '.png'">下载海报</a>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import PcVideo from "../../components/pc-video.vue";
import {
  liveInfo,
  livetitle,
  liveBrief,
  livePv,
  demand,
  watchSource,
} from "../../api/live";
import {
  unAppointment,
  appointment,
  userInfo,
  unCollect,
  collect,
  inviteInfo,
} from "../../api/user";
export default {
  components: {
    PcHeader,
    PcFooter,
    PcVideo,
  },
  metaInfo() {
    return {
      title: this.liveData.subject,
    };
  },
  data() {
    return {
      liveType: 0,
      id: 2,
      liveData: {},
      userData: {},
      spanActive: true,
      liveBriefData: "",
      allApplyBtn: false,
      collectStatus: false,
      inviteVisible: false,
      inviteImg: "",
      painting: {},
      info: {}
    };
  },
  created() {
    console.log(this.$route.query.st);
    sessionStorage.setItem(
      "st",
      this.$route.query.st ? this.$route.query.st : ""
    );
    this.getUserInfo();
    this.getLiveInfo();
  },
  // mounted() {
  //   if(window.history && window.history.pushState){
  //   window.addEventListener('popstate',function(){
  //     //  alert("我监听到了浏览器的返回按钮事件啦");//根据自己的需求实现自己的功能
  //     // parent.history.back()
  //     // this.$router.back()
  //     // this.$router.go(-2)
  //     location.href = "javascript:history.go(-2);"
  //   }, false)
  // }
  // },
  destroyed() {
    window.removeEventListener("popstate", this.goPageBefore, false);
  },
  methods: {
    incomeDetail(date) {
      let y = date.substring(0, 4)
      let M = date.substring(5, 7)
      let d = date.substring(8, 10)
      let time = date.substring(11)
    let dateStr = y + "/" + M + "/" + d;
    return `${y}年${M}月${d}日 星期${"日一二三四五六".charAt(new Date(dateStr).getDay())} ${time}`
    },
    // 生成邀请卡
    makeInvite() {
        if (!this.inviteImg) {
          inviteInfo({
        id: this.$route.params.id
      }).then(res => {
        if (res.code == 0) {
          this.info = res.data
          this.painting = {
            width: "1080px",
            height: "1916px",
            background: require("../../assets/invite.jpg"),
            views: [
              {
                type: "image",
                url: res.data.wxHeadImg || require('../../assets/defhead.png'),
                css: {
                  top: "450px",
                  left: "58px",
                  borderWidth: "5px",
                  borderRadius: "50%",
                  borderColor: "#ffffff",
                  width: "140px",
                  height: "140px",
                },
              },
              {
                type: 'text',
                text: res.data.wxNickName || res.data.userName,
                css: {
                  top: '470px',
                  left: '230px',
                  fontSize: '45px',
                  fontWeight: '600',
                  color: '#ffffff',
                  maxLines: 1,
                  width: '270px'
                }
              },
              {
                type: 'text',
                text: res.data.subject,
                css: {
                  top: '710px',
                  left: '170px',
                  fontSize: '56px',
                  lineHeight: '90px',
                  fontWeight: '600',
                  maxLines: 3,
                  width: '740px',
                  textAlign: 'center'
                }
              },
              {
                type: 'text',
                text: res.data.liveTime ? '直播时间' : '',
                css: {
                  top: '1055px',
                  left: '455px',
                  fontSize: '42px',
                  fontWeight: '600',
                }
              },
              {
                type: 'text',
                text: res.data.liveTime ? this.incomeDetail(res.data.liveTime) : '',
                css: {
                  top: '1130px',
                  left: '178px',
                  fontSize: '42px',
                  fontWeight: '600',
                  width: '730px',
                  textAlign: 'center'
                }
              },
              {
                type: 'qrcode',
                content: res.data.sourceUrl,
                css: {
                  top: '1330px',
                  left: '442px',
                  width: '190px',
                  height: '190px'
                }
              }
            ],
          }
          // if (res.data.wxHeadImg) {
          //   this.painting.views.push({
          //       type: "image",
          //       url: res.data.wxHeadImg,
          //       css: {
          //         top: "450px",
          //         left: "58px",
          //         borderWidth: "5px",
          //         borderRadius: "50%",
          //         borderColor: "#ffffff",
          //         width: "140px",
          //         height: "140px",
          //       },
          //     })
          // }
          // if (res.data.wxNickName) {
          //   this.painting.views.push(
          //     {
          //       type: 'text',
          //       text: res.data.wxNickName,
          //       css: {
          //         top: '470px',
          //         left: '230px',
          //         fontSize: '45px',
          //         fontWeight: '600',
          //         color: '#ffffff',
          //         maxLines: 1,
          //         width: '270px'
          //       }
          //     })
          // }
          this.inviteVisible = true;
          // console.log(this.incomeDetail(res.data.liveTime))
        }
      })
        } else {
          this.inviteVisible = true;
        }
      
      
    },
    closeInvite() {
      this.inviteVisible = false;
    },
    success(src) {
      // console.log(src);
      this.inviteImg = src
    },
    fail(err) {
      console.log("fail", err);
    },
    // 更改观看渠道
    watchSourceUpdate(id) {
      watchSource({
        webinarId: id,
        source: this.$route.query.st || "illumina",
      }).then();
    },
    // 查询直播类型
    getDemand(id) {
      demand({
        webinarId: id,
      }).then((res) => {
        if (res.code == 0) {
          // this.liveType = res.data
          let src = "";
          if (res.data == 1 || res.data == 4 || res.data == 5) {
            // 直播
            src = `https://live.vhall.com/v3/lives/embedclientfull/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`;
          } else {
            // 点播
            src = `https://live.vhall.com/v3/lives/embedclient/subscribe/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`;
          }
          this.$nextTick(() => {
            this.$refs.iframe.contentWindow.location.replace(src);
          });
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.userData = res.data;
        }
      });
    },
    // 查询直播信息
    getLiveInfo() {
      liveInfo({
        id: this.$route.params.id,
        sourceTag: this.$route.query.st ? this.$route.query.st : "illumina",
      }).then((res) => {
        if (res.code == 0) {
          this.getDemand(res.data.webinarId);
          this.watchSourceUpdate(res.data.webinarId);
          this.collectStatus = res.data.ymnCollect;
          if (res.data.validStatus == 1) {
            this.liveData = res.data;
            this.forInT();
            this.id = res.data.webinarId;
            this.getLiveBrief(res.data.webinarId);
            this.getLivePv(res.data.Liveid, res.data.webinarId);
            // this.collectStatus = res.data.ymnCollect ? true : false;
            // this.applyStatus = res.data.ymnSubscribe ? true : false;
            // if (res.data.liveStatus == 0) {
            //   this.applyShow = true;
            // }
          } else {
            // this.headerShow = false;
          }
        } else if (res.code == 60000) {
          livetitle({
            id: this.$route.params.id,
          }).then((res) => {
            if (res.code == 0) {
              if (res.data == null) {
                this.$router.replace({
                  path: "/login",
                  query: { w: this.$route.params.id, st: this.$route.query.st },
                });
              } else {
                this.$router.replace({
                  path: "/login",
                  query: {
                    r:
                      res.data.type == 0
                        ? "直播"
                        : res.data.type == 1
                        ? "点播"
                        : "",
                    c: res.data.subject,
                    w: this.$route.params.id,
                    st: this.$route.query.st,
                  },
                });
              }
            }
          });
        }
        // let src = ''
        console.log(this.liveType);
        // if (this.liveType == 4 || this.liveType == 5) {
        //   // 直播
        //   src = `https://live.vhall.com/v3/lives/embedclientfull/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`

        // } else {
        //   // 点播
        //   src = `https://live.vhall.com/v3/lives/embedclient/subscribe/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`
        // }
        // this.videoSrc = `https://live.vhall.com/v3/lives/embedclientfull/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`
        // this.$refs["iframe"].contentWindow.location.replace(
        //   `https://live.vhall.com/v3/lives/embedclient/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`
        // );
        // this.videoSrcVisible = true
        // this.$nextTick(() => {
        //   this.$refs.iframe.contentWindow.location.replace(
        //   this.liveSrc
        // );
        // })
      });
    },
    getLiveBrief(id) {
      liveBrief({
        webinarId: id,
      }).then((res) => {
        if (res.code == 0) {
          this.liveBriefData = res.data.data.introduction;
        }
      });
    },
    getLivePv(id, webid) {
      livePv({
        courseId: id,
        webinarId: webid,
        sourceTag: this.$route.query.st ? this.$route.query.st : "illumina",
      }).then();
    },
    // 判断相关课程有没有未开始的直播，有没有未预约的直播
    forInT() {
      var liveList = this.liveData.T;
      this.allApplyBtn = false;
      for (let i = 0; i < liveList.length; i++) {
        // console.log(liveList[i]['type'] + '---' + liveList[i]['liveStatus'] + )
        if (
          liveList[i]["type"] == 0 &&
          liveList[i]["liveStatus"] == 0 &&
          liveList[i]["ymnSubscribe"] == null
        ) {
          // 直播未开始
          //   // 有未预约的直播
          this.allApplyBtn = true;
        }
      }
    },
    // 一键预约
    allApplyFn() {
      appointment({
        channelId: this.liveData.channelId,
        // userId: this.userId,
        type: "1",
      }).then((res) => {
        if (res.code == 0) {
          this.$ylmessage.success("全部预约成功");
          this.getLiveInfo();
          // this.applyStatus = true;
          // this.appointmentStatus = 1;
        } else {
          this.$ylmessage.success(res.msg);
        }
      });
    },
    // 预约
    appointmentFn() {
      if (this.liveData.ymnSubscribe != null) {
        // 已预约
        unAppointment({
          id: this.liveData.ymnSubscribe.id,
          courseId: this.$route.params.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消预约");
            this.getLiveInfo();
            // this.applyStatus = false;
            // this.appointmentStatus = null;
          }
        });
      } else {
        // 预约
        appointment({
          courseId: this.$route.params.id,
          // userId: this.userId,
          type: "",
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("预约成功");
            this.getLiveInfo();
            // this.applyStatus = true;
            // this.appointmentStatus = 1;
          } else if (res.code == 60000) {
            this.$router.push({
              path: "/login",
              query: { w: this.$route.params.id },
            });
          }
        });
      }
    },
    // 点击收藏按钮
    collectFn() {
      console.log(1);
      if (this.collectStatus) {
        // 已收藏
        unCollect({
          id: this.liveData.ymnCollect.id,
          courseId: this.liveData.Liveid,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消收藏");
            this.collectStatus = false;
          }
        });
      } else {
        // 收藏
        collect({
          courseId: this.liveData.Liveid,
          // userId: this.userId,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("收藏成功");
            this.collectStatus = true;
            this.getLiveInfo();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
iframe {
  display: block;
}
p,
h1 {
  margin: 0;
}
.watch-pc {
  position: relative;
  height: 100%;
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;
    .top-bar {
      height: 70px;
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      .left {
        display: flex;
        align-items: center;
        .subject {
          font-size: 20px;
          font-weight: 600;
        }
        .time {
          padding-left: 30px;
          font-size: 14px;
        }
      }
      button {
        height: 36px;
        padding: 0 24px;
        border: 1px solid #fff;
        border-radius: 18px;
        background: rgba($color: #000000, $alpha: 0);
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .btn-box {
        display: flex;
        .invite {
        width:25px;
        height: 25px;
        background: url(../../assets/flay.png) 0 0 no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .icon-btn {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-left: 20px;
      }
      .nosel {
        background: url(../../assets/sle.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .yessel {
        background: url(../../assets/sle2.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
      }
      
    }
    .video-wrap {
      width: 100%;
      height: 594px;
    }
    .bottom-tab {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      height: 40px;
      border-bottom: 1px solid #ececec;
      .left {
        height: 100%;
        span {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          box-sizing: border-box;
          padding: 0 2px;
          cursor: pointer;
        }
        span.active {
          border-bottom: 2px solid #49b67b;
        }
        span:first-child {
          margin-right: 70px;
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 35px;
        height: 40px;
        background: #49b67b;
        font-size: 16px;
        color: #fff;
        border-radius: 20px;
        cursor: pointer;
        i {
          display: inline-block;
          margin-right: 8px;
          width: 28px;
          height: 28px;
          img {
            width: 100%;
            display: block;
          }
        }
        span {
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .live-box {
      .course,
      .info {
        padding-top: 40px;
      }
      .info {
        /deep/ img {
          margin: 4px auto;
          display: block;
          max-width: 100%;
        }
      }
      .course {
        display: flex;
        flex-wrap: wrap;
        .pc-video {
          margin-bottom: 35px;
        }
        .pc-video:nth-child(3n-1) {
          margin: 0 15px 35px;
        }
      }
    }
  }
  .apply-btn {
    position: absolute;
    top: 560px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    padding: 0 30px;
    border: none;
    border-radius: 20px;
    background: #49b67b;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  .apply-btn.active {
    background: #999;
  }
  .invite-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    .img-box {
      width: 380px;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      img {
        width: 100%;
        display: block;
      }
      .tips {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        p {
          margin: 0;
          font-size: 12px;
          color: #fff;
          height: 30px;
          line-height: 30px;
        }
        img {
          width: 30px;
          height: 30px;
        }
      }
      .down {
        text-align: center;
        padding-top: 30px;
        a {
          display: inline-block;
          width: 280px;
          height: 40px;
          line-height: 40px;
          background-color: #49b67b;
          border-radius: 20px;
          border: 0;
          color: #fff;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
