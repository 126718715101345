<template>
  <div class="watch-mob">
    <mob-header
      v-if="headerShow"
      :interaction="false"
      ref="header"
      :collectStatus="collectStatus"
      :collectData="liveData.ymnCollect"
      :liveStatus="liveData.liveStatus"
      @refDataList="getLiveInfo()"
      :watch="true"
      :invitationSwitch="liveData.invitation_switch"
    ></mob-header>
    <div
      v-if="iframeVisible"
      class="iframe-box"
      :style="{ height: clientHeight - headerHeight + 'px' }"
    >
      <iframe
        ref="iframe"
        border="0"
        frameborder="0"
        allowfullscreen="true"
        width="100%"
        height="100%"
        scrolling="auto"
      ></iframe>
    </div>
    <button
      v-if="applyShow"
      :class="['apply', liveData.ymnSubscribe ? 'active' : '']"
      @click="appointmentFn()"
    >
      {{ liveData.ymnSubscribe ? "已预约" : "立即预约" }}
    </button>
    <ym-popup
      v-if="popup"
      :allApplyBtn="allApplyBtn"
      @allApplyFn="allApplyFn()"
    >
      <mob-video
        v-for="(item, i) in liveData.T"
        :key="i"
        :data="item"
        @refDataList="getLiveInfo()"
      ></mob-video>
    </ym-popup>
  </div>
</template>

<script>
import wechat from "../../utils/wxShare";
import MobHeader from "../../components/mob-header.vue";
import YmPopup from "../../components/ym-popup.vue";
import MobVideo from "../../components/mob-video.vue";
import {
  liveInfo,
  livetitle,
  livePv,
  demand,
  watchSource,
} from "../../api/live";
import {
  unAppointment,
  appointment,
  userInfo,
  wechatConfig,
} from "../../api/user";
export default {
  components: {
    MobHeader,
    YmPopup,
    MobVideo,
  },
  metaInfo() {
    return {
      title: this.liveData.subject,
    };
  },
  data() {
    return {
      iframeVisible: false,
      liveType: 0,
      id: 2,
      liveData: {},
      clientHeight: 0,
      headerHeight: 0,
      applyShow: false,
      headerShow: true,
      collectStatus: false,
      applyStatus: false,
      userData: {},
      wxConfigData: {},
      popup: false,
      allApplyBtn: false,
      // rlen: history.length,
    };
  },
  created() {
    // console.log(this.$route.query.st)
    sessionStorage.setItem(
      "st",
      this.$route.query.st ? this.$route.query.st : ""
    );
    this.getUserInfo();
    this.getLiveInfo();
    // console.log(this.clientHeight);
    this.getWechatConfig();
  },
  mounted() {
    setTimeout(() => {
      this.getClientHeight();
      this.headerHeight += this.$refs.header.$el.offsetHeight;
      this.iframeVisible = true;
      this.getDemand(this.id);
    }, 500);
  },
  methods: {
    // 更改观看渠道
    watchSourceUpdate(id) {
      watchSource({
        webinarId: id,
        source: this.$route.query.st || "illumina",
      }).then();
    },
    // 查询直播类型
    getDemand(id) {
      demand({
        webinarId: id,
      }).then((res) => {
        if (res.code == 0) {
          // this.liveType = res.data
          let src = "";
          if (res.data == 1 || res.data == 4 || res.data == 5) {
            // 直播
            src = `https://live.vhall.com/v3/lives/embedclientfull/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`;
          } else {
            // 点播
            src = `https://live.vhall.com/v3/lives/embedclient/subscribe/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`;
          }
          this.$nextTick(() => {
            this.$refs.iframe.contentWindow.location.replace(src);
          });
        }
      });
    },
    // goBack () {
    //     let len = this.rlen - history.length - 1;//-1是不进入iframe页面的下级页面直接退出的话，执行后退一步的操作
    //     this.$router.go(len);
    // },
    //     pushHistory() {
    //     var state = {
    //         title: "title",
    //         url: "#"
    //     };
    //     window.history.pushState(state, "title", "#");
    // },
    //   goPageBefore(){
    //     // this.pushHistory()
    //     // alert(22131)
    //    //这里设置想要返回的页面，需要参数等。
    //   //  parent.history.back()
    //   // this.$router.replace({path: '/'});
    //   this.$router.go(-2)
    //  },
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            this.liveData.shareTitle,
            this.liveData.shareInfo,
            this.liveData.shareLogo,
            location.href
          );
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.userData = res.data;
        }
      });
    },
    getClientHeight() {
      this.clientHeight = window.innerHeight;
      // if (document.body.clientHeight && document.documentElement.clientHeight) {
      //   this.clientHeight =
      //     document.body.clientHeight < document.documentElement.clientHeight
      //       ? document.body.clientHeight
      //       : document.documentElement.clientHeight;
      // } else {
      //   this.clientHeight =
      //     document.body.clientHeight > document.documentElement.clientHeight
      //       ? document.body.clientHeight
      //       : document.documentElement.clientHeight;
      // }
    },
    // 查询直播数据
    getLiveInfo() {
      liveInfo({
        id: this.$route.params.id,
        sourceTag: this.$route.query.st ? this.$route.query.st : "illumina",
      }).then((res) => {
        if (res.code == 0) {
          // this.getDemand(res.data.webinarId);
          this.watchSourceUpdate(res.data.webinarId);
          if (res.data.validStatus == 1) {
            this.liveData = res.data;
            this.id = res.data.webinarId;
            this.collectStatus = res.data.ymnCollect ? true : false;
            this.applyStatus = res.data.ymnSubscribe ? true : false;
            if (res.data.T.length > 0) {
              this.popup = true;
            }
            this.getLivePv(res.data.Liveid, res.data.webinarId);
            if (res.data.liveStatus == 0 && res.data.type == 0) {
              this.applyShow = true;
            }
            this.forInT();
          } else {
            this.headerShow = false;
            this.headerHeight = 0;
          }
        } else if (res.code == 60000) {
          livetitle({
            id: this.$route.params.id,
          }).then((res) => {
            if (res.code == 0) {
              if (res.data == null) {
                this.$router.replace({
                  path: "/login",
                  query: { w: this.$route.params.id, st: this.$route.query.st },
                });
              } else {
                this.$router.replace({
                  path: "/login",
                  query: {
                    r:
                      res.data.type == 0
                        ? "直播"
                        : res.data.type == 1
                        ? "点播"
                        : "",
                    c: res.data.subject,
                    w: this.$route.params.id,
                    st: this.$route.query.st,
                  },
                });
              }
            }
          });
        }
        // let src = ''
        // if (this.liveType == 4) {
        //   // 直播
        //   src = `https://live.vhall.com/v3/lives/embedclientfull/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`

        // } else {
        //   // 点播
        //   src = `https://live.vhall.com/v3/lives/embedclient/subscribe/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`
        // }
        // // this.videoSrc = `https://live.vhall.com/v3/lives/embedclient/watch/${this.id}?email=${this.userData.email}&nickname=${this.userData.username}`
        // this.videoSrcVisible = true
        // this.$nextTick(() => {
        //   this.$refs.iframe.contentWindow.location.replace(
        //   src
        // );
        // })
      });
    },
    getLivePv(id, webid) {
      livePv({
        courseId: id,
        webinarId: webid,
        sourceTag: this.$route.query.st ? this.$route.query.st : "illumina",
      }).then();
    },
    // 判断相关课程有没有未开始的直播，有没有未预约的直播
    forInT() {
      var liveList = this.liveData.T;
      this.allApplyBtn = false;
      for (let i = 0; i < liveList.length; i++) {
        // console.log(liveList[i]['type'] + '---' + liveList[i]['liveStatus'] + )
        if (
          liveList[i]["type"] == 0 &&
          liveList[i]["liveStatus"] == 0 &&
          liveList[i]["ymnSubscribe"] == null
        ) {
          // 直播未开始
          //   // 有未预约的直播
          this.allApplyBtn = true;
        }
      }
    },
    // 一键预约
    allApplyFn() {
      appointment({
        channelId: this.liveData.channelId,
        // userId: this.userId,
        type: "1",
      }).then((res) => {
        if (res.code == 0) {
          this.$ylmessage.success("全部预约成功");
          this.getLiveInfo();
          // this.applyStatus = true;
          // this.appointmentStatus = 1;
        } else {
          this.$ylmessage.success(res.msg);
        }
      });
    },
    // 预约
    appointmentFn() {
      if (this.liveData.ymnSubscribe != null) {
        // 已预约
        unAppointment({
          id: this.liveData.ymnSubscribe.id,
          courseId: this.$route.params.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消预约");
            this.getLiveInfo();
            // this.applyStatus = false;
            // this.appointmentStatus = null;
          }
        });
      } else {
        // 预约
        appointment({
          courseId: this.$route.params.id,
          // userId: this.userId,
          type: "",
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("预约成功");
            this.getLiveInfo();
            // this.applyStatus = true;
            // this.appointmentStatus = 1;
          } else {
            this.$router.push({ path: "/login" });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.watch-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  iframe {
    background: #fff;
    display: block;
  }
  .apply {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.5rem;
    border: none;
    background: #1872bb;
    font-size: 0.16rem;
    color: #fff;
  }
  .apply.active {
    background: #999;
  }
}
</style>
