<template>
  <div class="page-view">
    <watch-mob v-if="isMobile"></watch-mob>
    <watch-pc v-else></watch-pc>
  </div>
</template>

<script>
import WatchPc from "./watch-pc.vue";
import WatchMob from "./watch-mob.vue";
export default {
  components: {
    WatchPc,
    WatchMob,
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  created() {
    sessionStorage.setItem(
      "st",
      this.$route.query.st ? this.$route.query.st : "illumina"
    );
  },
};
</script>
